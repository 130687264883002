import ArrowUpIcon from './icons/ArrowUpIcon';
import CreateDocumentIcon from './icons/CreateDocumentIcon';
import HeaderHelpIcon from './icons/HeaderHelpIcon';
import HeaderMoreMenuIcon from './icons/HeaderMoreMenuIcon';
import HeaderNotificationIcon from './icons/HeaderNotificationIcon';
import DashboardHomeBannerImg from './images/DashboardHomeBannerImg.png';
import EmptyScreenImage1 from './images/EmptyScreenImage1.png';
import AddRecipientEmptyScreenImg from './images/AddRecipientEmptyScreenImg.png';
import ArrowDownIcon from './icons/ArrowDownIcon';
import DashboardHomeOrange from './images/dashboard-home/home_orange.png';
import DashboardHomeBlue from './images/dashboard-home/home_blue.png';
import DashboardHomeGreen from './images/dashboard-home/home_green.png';
import DashboardHomePurple from './images/dashboard-home/home_purple.png';

import WhyChooseDigiEsignImage from './icons/public_home/WhyChooseDigiEsignImage.png';
import FooterBannerImage from './icons/public_home/FooterBannerImage.png';
import FirstBannerImage2 from './icons/public_home/HomeFirstBannerImage.png';

import PratikNairImg from './images/testimonials/pratik_nair_img.png';
import DeepakSurieImg from './images/testimonials/deepak_surie_img.png';
import DhananjaiImg from './images/testimonials/dhananjai_img.png';
import AvinashImg from './images/testimonials/avinash_img.png';
import VerdantHolidaysImg from './images/testimonials/verdant_img.png';
import AadharLogo from './icons/AadharLogo.png';
import WordLogo from './icons/template/msWordLogo.png';
import HomeBannerVideo from './videos/digiesignMainBannerExplainer.mov';
const EsignAppAssets = {
  HeaderMoreMenuIcon,
  HeaderHelpIcon,
  HeaderNotificationIcon,
  DashboardHomeBannerImg,
  EmptyScreenImage1,
  CreateDocumentIcon,
  ArrowUpIcon,
  AddRecipientEmptyScreenImg,
  ArrowDownIcon,
  DashboardHomeOrange,
  DashboardHomeBlue,
  DashboardHomeGreen,
  DashboardHomePurple,
  WhyChooseDigiEsignImage,
  FooterBannerImage,
  FirstBannerImage2,
  PratikNairImg,
  DeepakSurieImg,
  DhananjaiImg,
  AvinashImg,
  VerdantHolidaysImg,
  AadharLogo,
  WordLogo,
  HomeBannerVideo
};
export const WordLogoIcon = WordLogo;
export default EsignAppAssets;
