import { createSlice } from '@reduxjs/toolkit';
import { AllCanvasTypes } from 'utils';

export const EsignFooterActionsValueConst = {
  IsNextButtonDisabled: 'IsNextButtonDisabled'
};

const initialState = {
  esignSelectedSignType: {},
  esignPdfPagesPosition: [],
  esignCurrentDocSelectedPage: 0,
  isProgrammaticScroll: false,
  esignFormCurrentStep: 0,
  selectedActiveObject: null,
  addedReceipientList: [],
  esignUploadedDocumentsImages: [],
  combinedSignAndUnSignedNode: [],
  usersNodes: [],
  esignFormCurrentStep: 0,
  isPlaceHolderCanvasVisitedBefore: false,
  esignFormFooterActions: { [EsignFooterActionsValueConst.IsNextButtonDisabled]: true },
  esignPdfImageScaleValue: null,
  tempEsignUploadedDocumentsImages: [],
  isCanvasLoading: { canvasType: AllCanvasTypes.placeHolderCanvas, status: true },
  formStepperData: { completedTill: 0, current: 1, last: 3 },
  uploadedDocDetails: {},
  esignMainAppLoader: false,
  uploadedDocumentAllData: {},
  isSigningOrder: false,
  recipientMessage: { subject: null, description: null },
  alreadyUsedPlaceholder: [],
  recipientExpiryData: [],
  isIamTheOnlySigner: false,
  templateForUpload: null
};

const esignMainSlice = createSlice({
  name: 'esignMain',
  initialState,
  reducers: {
    setEsignSelectedSignType(state, action) {
      state.esignSelectedSignType = action.payload;
    },

    setEsignPdfPagesPosition(state, action) {
      state.esignPdfPagesPosition = action.payload;
    },
    setEsignCurrentDocSelectedPage(state, action) {
      state.esignCurrentDocSelectedPage = action.payload;
    },
    setIsProgrammaticScroll(state, action) {
      state.isProgrammaticScroll = action.payload;
    },
    setEsignFormCurrentStep(state, action) {
      state.esignFormCurrentStep = action.payload;
    },
    setSelectedActiveObject(state, action) {
      state.selectedActiveObject = action.payload;
    },
    setAddedReceipientList(state, action) {
      state.addedReceipientList = action.payload;
    },
    updateAddedReceipientList(state, action) {
      state.addedReceipientList = [...state.addedReceipientList, action.payload];
    },

    setEsignUploadedDocumentsImages(state, action) {
      state.esignUploadedDocumentsImages = action.payload;
    },
    setCombinedSignAndUnSignedNodeRdx(state, action) {
      state.combinedSignAndUnSignedNode = action.payload;
    },
    setUsersNodes(state, action) {
      state.usersNodes = action.payload;
    },
    setEsignFormCurrentStep(state, action) {
      state.esignFormCurrentStep = action.payload;
    },
    goBackEsignFormCurrentStep(state, action) {
      state.esignFormCurrentStep = state.esignFormCurrentStep - 1;
    },
    goNextEsignFormCurrentStep(state, action) {
      console.log(state.esignFormCurrentStep, action.payload, 'activeStepVk --2');
      state.esignFormCurrentStep = state.esignFormCurrentStep + 1;
    },
    setIsPlaceHolderCanvasVisitedBefore(state, action) {
      state.isPlaceHolderCanvasVisitedBefore = action.payload;
    },
    setEsignFormFooterActions(state, { payload: { key, value } }) {
      console.log(state.esignFormFooterActions, { key, value }, 'footerActionsValue --2');
      state.esignFormFooterActions = { ...state.esignFormFooterActions, [key]: value };
    },
    setEsignPdfImageScaleValue(state, { payload }) {
      state.esignPdfImageScaleValue = payload;
    },
    setTempEsignUploadedDocumentsImages(state, action) {
      state.tempEsignUploadedDocumentsImages = action.payload;
    },
    setIsCanvasLoading(state, { payload: { canvasType, status } }) {
      const data = { canvasType, status };
      state.isCanvasLoading = data; //{canvasType:'',status:boolean}
    },
    setFormStepperData(state, { payload }) {
      state.formStepperData = { ...state.formStepperData, ...payload };
    },
    setUploadedDocDetails(state, { payload }) {
      state.uploadedDocDetails = payload;
    },
    setEsignMainAppLoader(state, { payload }) {
      state.esignMainAppLoader = payload;
    },
    setUploadedDocumentAllData(state, { payload }) {
      state.uploadedDocumentAllData = payload;
    },
    setIsSigningOrderRdx(state, { payload }) {
      state.isSigningOrder = payload;
    },
    setRecipientMessageRdx(state, { payload: { type, value } }) {
      state.recipientMessage = { ...state.recipientMessage, [type]: value };
    },
    setAlreadyUsedPlaceholder(state, { payload }) {
      state.alreadyUsedPlaceholder = payload;
    },
    setRecipientExpiryData(state, { payload }) {
      state.recipientExpiryData = payload;
    },
    setIsIamTheOnlySignerRdx(state, { payload }) {
      state.isIamTheOnlySigner = payload;
    },
    setTemplateForUpload(state, { payload }) {
      state.templateForUpload = payload;
    }
  }
});

export const {
  setEsignSelectedSignType,
  setEsignPdfPagesPosition,
  setEsignCurrentDocSelectedPage,
  setIsProgrammaticScroll,
  setEsignFormCurrentStep,
  setSelectedActiveObject,
  setAddedReceipientList,
  updateAddedReceipientList,
  setEsignUploadedDocumentsImages,
  setTempEsignUploadedDocumentsImages,
  setCombinedSignAndUnSignedNodeRdx,
  setUsersNodes,
  goBackEsignFormCurrentStep,
  goNextEsignFormCurrentStep,
  setIsPlaceHolderCanvasVisitedBefore,
  setEsignFormFooterActions,
  setEsignPdfImageScaleValue,
  setIsCanvasLoading,
  setFormStepperData,
  setUploadedDocDetails,
  setEsignMainAppLoader,
  setUploadedDocumentAllData,
  setIsSigningOrderRdx,
  setRecipientMessageRdx,
  setAlreadyUsedPlaceholder,
  setRecipientExpiryData,
  setIsIamTheOnlySignerRdx,
  setTemplateForUpload
} = esignMainSlice.actions;

export const selectEsignFormCurrentStep = (state) => state.esignMain.esignFormCurrentStep;
export const selectUploadedDocsImages = (state) => state.esignMain.esignUploadedDocumentsImages;
export const selectCombinedSignAndUnSignedNode = (state) => state.esignMain.combinedSignAndUnSignedNode;
export const selectUsersNodes = (state) => state.esignMain.usersNodes;
export const getIsPlaceHolderCanvasVisitedBefore = (state) => state.esignMain.isPlaceHolderCanvasVisitedBefore;
export const getEsignFormFooterActionsValue = (key) => (state) => state.esignMain.esignFormFooterActions[key];
export const getEsignPdfImageScaleValue = (state) => state.esignMain.esignPdfImageScaleValue;

export const changeIsNextButtonDisabled = (value) =>
  setEsignFormFooterActions({ key: EsignFooterActionsValueConst.IsNextButtonDisabled, value: value });
export default esignMainSlice.reducer;
export const getIsCanvasLoading = (state) => state.esignMain.isCanvasLoading;
export const getStepperDataFromRdx = (state) => state.esignMain.formStepperData;
