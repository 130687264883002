import axios from 'axios';
import { defaultTimezone } from 'context/TimezoneContext';
import { toast } from 'react-toastify';
import { clearAllLocalStorage } from 'utils/esign.helper';
import { SessionExpired } from 'utils/toastMessages';
export const singleSignOnBase = 'https://dev-singin.lexops.co';
export const baseApiUrl = 'https://api.digiesign.in/';
// export const baseApiUrl = 'https://dev-api.digiesign.in/';
export const baseApiUrlDev = 'https://dev-esignapi.kronicle.in/';
let controller = new AbortController();
export const ProdDigiEsignBaseUrl = 'https://digiesign.in/';
// const assessApiBaseUrl = 'https://dev-assessapi.kronicle.in/';
const assessApiBaseUrl = 'https://apis.digiebooks.in/';
// export const noticesApiBaseUrl = 'https://dev-api-notices.kronicle.in/';
export const noticesApiBaseUrl = 'https://api-notices.kronicle.in/';
const instance = axios.create({
  baseURL: baseApiUrl
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Retrieve the token from local storage
    if (token) {
      // Set the token in the request headers
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    // Return the response if it is successful
    return response;
  },
  (error) => {
    // Check if the error status is 401
    if (error.response && error.response.status === 401) {
      toast.error(SessionExpired);
      controller.abort();
      // Handle the redirect
      setTimeout(() => {
        clearAllLocalStorage();
        window.location.href = '/login';
      }, 2000);
    }
    return Promise.reject(error);
  }
);

instance.interceptors.request.use((config) => {
  config.signal = controller.signal;
  return config;
});
const axiosInstance = instance;
export default axiosInstance;
export const pdfToImageConvert = async (payload) => {
  try {
    const res = await instance.post(`/pdf-to-image-converter`, payload);
    return res;
  } catch (error) {
    return error.message;
  }
};
export const uploadDocument = async (payload) => {
  try {
    const res = await instance.post(`/documents/upload`, payload);
    return res;
  } catch (error) {
    return error.message;
  }
};

export const getDocumentById = async (docId) => {
  try {
    const res = await instance.get(`documents/${docId}/read`);
    return res;
  } catch (error) {
    return error.message;
  }
};
export const getDocumentByIdPreview = async (docId) => {
  try {
    const res = await instance.get(`documents/${docId}/read?preview=true`);
    return res;
  } catch (error) {
    return error.message;
  }
};
export const documentSignConfig = async (docId, payload) => {
  try {
    const res = await instance.post(`documents/${docId}/sign_configs/create`, payload);
    return res;
  } catch (error) {
    return error.message;
  }
};

export const createSignConfig = async (docId, email, payload) => {
  try {
    const timeZone = defaultTimezone;
    const res = await instance.post(`documents/${docId}/signs/${email}/create`, { ...payload, app_name: 'DE', time_zone: timeZone });
    return res;
  } catch (error) {
    return error.message;
  }
};
export const getSignConfig = async (docId) => {
  try {
    const res = await instance.get(`documents/${docId}/sign_configs/read`);
    return res;
  } catch (error) {
    return error.message;
  }
};

//get document data using link

export const getDocUsingLink = async (docId) => {
  try {
    const res = await instance.get(`documents/${docId}/link/read`);
    return res;
  } catch (error) {
    return error.message;
  }
};

//get document data list

export const getDocList = async () => {
  try {
    const res = await instance.get(`documents/list`);
    return res;
  } catch (error) {
    return error.message;
  }
};

// const instance = axios.create({
//   baseURL: 'http://159.89.161.222:8095/',
// });

// instance.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem('token'); // Retrieve the token from local storage
//     if (token) {
//       // Set the token in the request headers
//       config.headers['Authorization'] = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// export const sendPDFtoMail = async (payload) => {
//   try {
//     const { data } = await instance.post(`send-mail`, payload);
//     return data;
//   } catch (err) {
//     return err;
//   }
// }

const eSignURL = process.env.ESIGN_URL;

export function UploadDocForSign(payload, endpoint) {
  return instance.post(`${eSignURL}${endpoint}`, payload).then((response) => {
    console.log(response);
    return response.data;
  });
}

//add aadhar authentication

export const aadharSignByVsign = async (payload) => {
  try {
    const res = await instance.post(`${baseApiUrl}vsign/vsign_txn_ref/`, { ...payload, app_name: 'DE' });
    console.log(res, 'resData');
    if (res.status === 200 && res?.data?.status) {
      return res?.data?.data?.txnref;
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const downloadEsignDoc = async (docId, type = 'document') => {
  try {
    // Add necessary headers
    const headers = {
      'Content-Type': 'application/json'
    };

    // Make the request
    const res = await axios.post(
      `${baseApiUrl}documents/download_document`,
      { document_id: docId, type },
      {
        responseType: 'arraybuffer', // Set response type to arraybuffer for binary data
        headers
      }
    );

    console.log(res, 'resData');

    if (res.status === 200) {
      return res.data; // Binary data (arraybuffer)
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

//************ Home Dashboard Apis */
export const getHomeDashboardStatus = async (email) => {
  try {
    const res = await instance.get(`documents/sign_status/${email}/read`);
    return res?.data;
  } catch (error) {
    return error.message;
  }
};

//************* Sender Flow Apis ***************** */

export const createRecipientSignConfig = async (documentId, payload) => {
  try {
    const timeZone = defaultTimezone;

    const res = await instance.post(`documents/${documentId}/recipient_configs/create`, {
      ...payload,
      app_name: 'DE',
      time_zone: timeZone
    });
    if (res.status === 200) {
      return res?.data;
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const documentInboxList = async (email, payload) => {
  try {
    const res = await instance.get(`documents/list?email=${email}`, { params: payload });
    return res?.data;
  } catch (error) {
    return error.message;
  }
};

export const esignDocumentDeleteApi = async (documentId) => {
  try {
    const res = await instance.delete(`documents/list/${documentId}/`);
    return res?.data;
  } catch (error) {
    return error.message;
  }
};

export function listSearchDebounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

export const getRecipientStatus = async (documentId, setLoadiing) => {
  try {
    setLoadiing(true);
    const res = await instance.get(`/documents/${documentId}/recipient_status/read`);
    return res?.data;
  } catch (error) {
    return error.message;
  } finally {
    setLoadiing(false);
  }
};

export const archiveDocumentApi = async (docmentIds, { ...payload }) => {
  try {
    const res = await instance.delete(`documents/list`, {
      data: {
        document_ids: docmentIds,
        ...payload
      }
    });
    return res?.data;
  } catch (error) {
    return error.message;
  }
};

export const getNotifications = async ({ isUnread = false }, setLoadiing) => {
  try {
    let parameter = '';
    if (isUnread) {
      parameter = `?unread=true`;
    } else setLoadiing(true);
    const res = await instance.get(`/documents/notification/list${parameter}`);
    return res?.data;
  } catch (error) {
    return error.message;
  } finally {
    setLoadiing(false);
  }
};

export const uploadUserSignature = async (sign_document) => {
  try {
    const res = await instance.post(`/signature/upload`, { sign_document: sign_document });
    if (res.status === 201) {
      return res?.data;
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const updateUserPreferences = async (payload) => {
  try {
    const res = await instance.post(`/documents/user_mapping`, { ...payload });
    if (res.status === 201) {
      return res?.data;
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getUserPreferences = async () => {
  try {
    const res = await instance.get(`/documents/user_mapping`);
    if (res.status === 200) {
      return res?.data;
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getUserSignature = async (sign_document) => {
  try {
    const res = await instance.get(`/signature/upload`);
    if (res.status === 200) {
      return res?.data;
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const emailReminderPostApi = async (email, docId) => {
  try {
    const res = await instance.post(`/documents/send_email`, { email: email, document_id: docId, app_name: 'DE' });
    if (res.status === 200) {
      return res?.data;
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const updateDocumentStatus = async ({ documentId, userEmail, status, payload = {} }) => {
  try {
    const res = await instance.post(`${baseApiUrl}documents/${documentId}/document_status/${userEmail}/create`, {
      status,
      ...payload,
      app_name: 'DE'
    });
    if (res.status === 200) {
      return res?.data;
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getRecentActivityApi = async (sign_document) => {
  try {
    const res = await instance.get(`/documents/activity_log/list`);
    if (res.status === 200) {
      return res?.data;
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const updateReadFlag = async (documentId, payload) => {
  try {
    const res = await instance.post(`${baseApiUrl}documents/update_read_status/`, {
      document_ids: documentId,
      read: true,
      ...payload
      // app_name: 'DE'
    });
    if (res.status === 200) {
      return res?.data;
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getSingleSignOnUrl = (redirect) => {
  const prod = 'https://dev-esign.lexops.co/dashboard/home';
  const dev = 'http://localhost:3000/dashboard/home';
  return `${singleSignOnBase}?redirect=${prod}`;
};

export const getRecipientList = async ({ document_id }, setLoadiing) => {
  try {
    setLoadiing(true);
    const res = await instance.get(`${baseApiUrl}documents/${document_id}/recipient_list/`);
    if (res.status === 200) {
      return res?.data;
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    throw new Error(error.message);
  } finally {
    setLoadiing(false);
  }
};

export const updateExpirationSetting = async (documentId, payload, setLoading) => {
  try {
    setLoading(true);
    const res = await instance.post(`${baseApiUrlDev}documents/${documentId}/recipient_list/`, {
      ...payload,
      app_name: 'DE'
    });
    if (res.status === 200) {
      return res?.data;
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    throw new Error(error.message);
  } finally {
    setLoading(false);
  }
};

export const getBlogsService = async () => {
  try {
    const res = await instance.get(`https://cms.kronicle.in/api/cms/list/blog`);
    if (res.status === 200) {
      console.log(res?.data, 'resTData');
      return res?.data;
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    console.log(error.message, 'errorMessafeData');
  } finally {
  }
};

export const getBlogBySlug = async (slug) => {
  try {
    const res = await instance.get(`https://cms.kronicle.in/api/cms/list/blog/${slug}`);
    if (res.status === 200) {
      console.log(res?.data, 'resTData');
      return res?.data;
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    console.log(error.message, 'errorMessafeData');
  } finally {
  }
};

export const getAllPagesFromCms = async (slug = '') => {
  try {
    const res = await instance.get(`https://cms.kronicle.in/api/cms/list/webpage${slug}`);
    if (res.status === 200) {
      console.log(res?.data, 'resTData');
      return res?.data;
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    console.log(error.message, 'errorMessafeData');
  } finally {
  }
};

export const getAllFaqsApi = async (slug) => {
  try {
    const res = await instance.get(`https://cms.kronicle.in/api/cms/list/faq`);
    if (res.status === 200) {
      console.log(res?.data, 'resTData');
      return res?.data;
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    console.log(error.message, 'errorMessafeData');
  } finally {
  }
};

export const getAllCaseStudies = async (slug) => {
  try {
    const res = await instance.get(`https://cms.kronicle.in/api/cms/list/case-study`);
    if (res.status === 200) {
      console.log(res?.data, 'resTData');
      return res?.data;
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    console.log(error.message, 'errorMessafeData');
  } finally {
  }
};

export const updateAccountSetting = async (payload, setLoading) => {
  try {
    setLoading(true);
    const res = await instance.post(`${assessApiBaseUrl}users/update-password-phone`, {
      ...payload
    });
    if (res.status === 200) {
      return res?.data;
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    throw new Error(error.message);
  } finally {
    setLoading(false);
  }
};

export const getAllTemplatesDepartments = async () => {
  try {
    const res = await instance.get(`${assessApiBaseUrl}additor-template/department`);
    if (res.status === 200) {
      console.log(res?.data, 'resTData');
      return res?.data;
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    console.log(error.message, 'errorMessafeData');
  } finally {
  }
};

export const getAllTemplates = async (payload = {}) => {
  try {
    const res = await instance.post(`${assessApiBaseUrl}additor-template/library`, payload);
    if (res.status === 200) {
      console.log(res?.data, 'resTData');
      return res?.data;
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    console.log(error.message, 'errorMessafeData');
  } finally {
  }
};

export const getAllTemplatesForLoggedInUser = async (payload = {}) => {
  try {
    const res = await instance.post(`${assessApiBaseUrl}additor-template/list`, payload);
    if (res.status === 200) {
      console.log(res?.data, 'resTData');
      return res?.data;
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    console.log(error.message, 'errorMessafeData');
  } finally {
  }
};

const handleDownload = (blob) => {
  // Create a link element
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob); // Create an object URL from the Blob
  link.href = url;
  link.download = 'Template_Download.docx'; // Set the default file name (you can make this dynamic)

  // Trigger the download by programmatically clicking the link
  document.body.appendChild(link); // Append the link to the DOM (required for some browsers)
  link.click();
  document.body.removeChild(link); // Remove the link after the click

  // Clean up the Blob URL
  URL.revokeObjectURL(url);
};

export const handleDownloadTemplate = async (templateId) => {
  try {
    // Fetch the template file as a Blob
    const res = await axios.get(`${noticesApiBaseUrl}template-download/${templateId}`, {
      responseType: 'blob' // Important: specify 'blob' for binary file responses
    });
    console.log(res, 'resData');
    return res.data;
  } catch (error) {
    console.log(error.message, 'errorMessafeData');
  }
};

export const copyTemplateToCurrentUserApi = async (templateId) => {
  try {
    // Fetch the template file as a Blob
    const res = await axios.post(`${assessApiBaseUrl}additor-template/copy-template/${templateId}`);
    console.log(res, 'resData');
    return res.data;
  } catch (error) {
    console.log(error.message, 'errorMessafeData');
  }
};
