import axios from 'axios';
import { toast } from 'react-toastify';
import { clearAllLocalStorage } from 'utils/esign.helper';

export const getAllUsers = async () => {
  try {
    const { data } = await axios.get(`users`);
    return data;
  } catch (err) {
    return err;
  }
};
export const createUser = async (payload) => {
  try {
    const { data } = await axios.post(`users/`, payload);
    return data;
  } catch (err) {
    return err;
  }
};
export const addOrganization = async (username, payload) => {
  try {
    const { data } = await axios.patch(`users/${username}`, payload);
    return data;
  } catch (err) {
    return err;
  }
};
export const addSubscription = async (userId, payload) => {
  try {
    const { data } = await axios.post(`users/${userId}/subscriptions`, payload);
    return data;
  } catch (err) {
    return err;
  }
};
export const getUserSubscription = async (userId, payload) => {
  try {
    const { data } = await axios.get(`users/${userId}/subscriptions`, payload);
    return data;
  } catch (err) {
    return err;
  }
};
export const getMyUserDetails = async () => {
  try {
    const { data } = await axios.get(`users/my-details`);
    return data;
  } catch (err) {
    if (err?.response?.data?.detail) {
      toast.error(err?.response?.data?.detail);
    }
    console.log(err?.response?.data?.detail, 'responseDtaa');
    if (err?.response?.status === 401) {
      clearAllLocalStorage();
    }
    console.log(err, err.response.status, 'errorPopData');
    return err;
  }
};
export const createUserOrganization = async (payload) => {
  try {
    const { data } = await axios.post(`users/organisations`, payload);
    return data;
  } catch (err) {
    return err;
  }
};
export const assignProductToUser = async (userId, productId, payload) => {
  try {
    const { data } = await axios.post(`users/${userId}/assigned-products/${productId}`, payload);
    return data;
  } catch (err) {
    return err;
  }
};
export const deassignUserFromProduct = async (userId, productId) => {
  try {
    const { data } = await axios.delete(`users/${userId}/assigned-products/${productId}`);
    return data;
  } catch (err) {
    return err;
  }
};
export const changeUserStatus = async (userId, payload) => {
  try {
    const { data } = await axios.post(`users/${userId}/change-user-status`, payload);
    return data;
  } catch (err) {
    return err;
  }
};
export const deleteUser = async (userId) => {
  try {
    const { data } = await axios.delete(`users/${userId}/delete-user`);
    return data;
  } catch (err) {
    return err;
  }
};

//new api for assign subscription from rootuser
export const addSubscriptionSuperAdmin = async (userId, payload) => {
  try {
    const { data } = await axios.post(`${userId}/super-admin/subscription`, payload);
    return data;
  } catch (err) {
    return err;
  }
};
