import EsignAppAssets from 'app/assets';
import React from 'react';
const HomePageVideoBanner = () => {
  return (
    <div className="flex items-center justify-center mb-[8%] sm:mb-[70px] shadow-lg rounded-[2px] sm:rounded-[10px]">
      {/* <img
        // src={EsignAppAssets.FirstBannerImage2}
        alt="banner1"
        className="  rounded-[2px] sm:rounded-[10px] w-full h-full object-contain"
      /> */}
      <video
        src={EsignAppAssets.HomeBannerVideo}
        className="rounded-[10px] p-[4px] sm:rounded-[10px] w-full h-full object-contain"
        loop
        autoPlay
        muted
        controls={false}
      />
    </div>
  );
};

export default HomePageVideoBanner;
